import React, { createContext, useState, useCallback, useMemo } from 'react';
import decode from 'jwt-decode';

export const AuthContext = createContext({
  username: '',
  userEmail: '',
  userFullName: '',
  isTokenPresent: false,
  loginTime: null,
  expiryTime: null,
  login: () => {},
  logout: () => {}
});

export const AuthProvider = ({ children }) => {
  const [username, setUsername] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userFullName, setUserFullName] = useState('');
  const [loginTime, setLoginTime] = useState(null);
  const [expiryTime, setExpiryTime] = useState(null);
  const [isTokenPresent, setIsTokenPresent] = useState(false);

  // Memoized login function
  const login = useCallback((token) => {
    try {
      const { username, emailId, fullName, iat, exp } = decode(token);
      setUsername(username);
      setUserEmail(emailId);
      setUserFullName(fullName);
      setLoginTime(iat);
      setExpiryTime(exp);
      setIsTokenPresent(true);
      localStorage.setItem('token', token);
    } catch (error) {
      console.error('Failed to decode token during login', error);
    }
  }, []);

  // Memoized logout function
  const logout = useCallback(() => {
    localStorage.removeItem('token');
    setUsername('');
    setUserEmail('');
    setUserFullName('');
    setLoginTime(null);
    setExpiryTime(null);
    setIsTokenPresent(false);
  }, []);

  if (!isTokenPresent) {
    const jwtToken = localStorage.getItem('token');
    if (jwtToken) {
      try {
        const { username, fullName, emailId, iat, exp } = decode(jwtToken);
        setUsername(username);
        setUserEmail(emailId);
        setUserFullName(fullName || username);
        setLoginTime(iat);
        setExpiryTime(exp);
        setIsTokenPresent(true);
      } catch (error) {
        console.error('Invalid token', error);
        logout();
      }
    }
  }

  // Memoize the context value to prevent unnecessary re-renders
  const authValue = useMemo(
    () => ({
      username,
      userEmail,
      userFullName,
      isTokenPresent,
      loginTime,
      expiryTime,
      login,
      logout
    }),
    [
      username,
      userEmail,
      userFullName,
      isTokenPresent,
      loginTime,
      expiryTime,
      login,
      logout
    ]
  );

  return (
    <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>
  );
};
