import React from 'react';
import { publicRoutes, authProtectedRoutes } from './routes';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import AppRoute from './routes/route';
import './scss/main.scss';
import './App.css';
import i18n from './i18n';

const App = () => {
  return (
    <div>
      <Router>
        <Switch>
          {authProtectedRoutes.map((route, idx) => (
            <AppRoute
              path={route.path}
              component={route.component}
              key={idx}
              isAuthProtected={true}
            />
          ))}
          {publicRoutes.map((route, idx) => (
            <AppRoute
              path={route.path}
              component={route.component}
              key={idx}
              isAuthProtected={false}
            />
          ))}
        </Switch>
      </Router>
    </div>
  );
};

export default App;
