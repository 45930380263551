import React, { useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import CommonLandingPage from './CommonLandingPage';
import { axiosAdapter } from '../../utils';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import ChevronRight from '../../assets/images/Chevron-left.svg';
import styled from 'styled-components';
import Column from '../../components/common/_customColumn';
import CustomRow from '../../components/common/_customRow';
import { StyledButtonPrimary } from '../../components/common/_buttonNewOne';
import { CREATE_OTP } from '../../consts/configConstants';
import idConstants from '../../consts/idConstants';
import i18next from 'i18next';

const Text = styled.p`
  font-family: 'Articulat CF Medium';
  margin: 0;
`;

const InputField = styled.input`
  font-family: 'Articulat CF Medium';
  font-size: 16px;
  font-weight: 500;
  color: #fafafa;
  background-color: inherit;
  outline: none;
  border: none;
  width: 100%;
  &::placeholder {
    color: #b0b0b0;
  }
`;

const InputFieldContainer = styled.div`
  padding: 11px 12px;
  width: 100%;
  border-radius: 12px;
  border: none;
  background-color: #2f2f2f;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #2f2f2f;
`;

const ForgotPassword = () => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);

  const handleEmailChange = (event) => setEmail(event.target.value);

  const checkEmail = () => {
    if (!idConstants.regex.forgotEmailId.test(email)) {
      setIsEmailValid(false);
      setErrorMessage(i18next.t('MESSAGES.ERROR.ENTER_VALID_EMAIL'));
      return false;
    } else {
      return true;
    }
  };

  const handleClickHandler = async () => {
    setIsEmailValid(true);
    setErrorMessage('');
    const checkEmailResponse = await checkEmail();
    const data = { emailId: email, isNewAccount: false };
    if (checkEmailResponse === true) {
      try {
        await axiosAdapter(idConstants.axiosMethods.post, CREATE_OTP, data);
        history.push(idConstants.path.forgotPasswordOtpVerification, data);
      } catch (err) {
        if (err?.response?.data?.code === 409) {
          setErrorMessage(err?.response?.data.remarks);
          setIsEmailValid(false);
        } else {
          setErrorMessage(i18next.t('VALIDATION.OTP_FAILED'));
        }
      }
    }
  };

  return (
    <CommonLandingPage>
      <CustomRow>
        <Column style={{ marginBottom: '40px' }}>
          <Text
            style={{ color: '#F6F6F6', fontSize: '22px', lineHeight: '31px' }}
          >
            {i18next.t('HELPER_TEXTS.FORGOT_YOUR_PASSWORD')}
          </Text>
        </Column>
        <Column style={{ marginBottom: errorMessage ? '10px' : '40px' }}>
          <InputFieldContainer
            style={!isEmailValid ? { borderColor: '#EA3A3A' } : {}}
          >
            <InputField
              placeholder={i18next.t('PLACEHOLDERS.WORK_EMAIL')}
              name='email'
              onChange={handleEmailChange}
            />
          </InputFieldContainer>
        </Column>
        {errorMessage && (
          <Column style={{ marginBottom: '12px' }}>
            <Text style={{ color: '#EA3A3A', fontSize: '12px' }}>
              {errorMessage}
            </Text>
          </Column>
        )}
        <Column style={{ marginBottom: '40px' }}>
          <StyledButtonPrimary
            onClick={handleClickHandler}
            disabled={!email}
            style={{ fontFamily: 'Articulat CF Medium', width: '204px' }}
          >
            Send OTP
          </StyledButtonPrimary>
        </Column>
        <Column>
          <Link to={idConstants.path.login}>
            <Text style={{ color: '#CCCCCC', fontSize: '16px' }}>
              <img
                src={ChevronRight}
                alt={i18next.t('PLACEHOLDERS.CHEVRON_ALT')}
                style={{ height: '12px', width: '12px', marginRight: '10px' }}
              />
              {i18next.t('HELPER_TEXTS.BACK_TO_LOGIN')}
            </Text>
          </Link>
        </Column>
      </CustomRow>
    </CommonLandingPage>
  );
};

export default withRouter(ForgotPassword);
