import React, { useState } from 'react';
import i18next from 'i18next';
import { Col, Row } from 'reactstrap';
import CustomPagination from '../components/CustomPagination';
import { GET_ALL_DEPLOYMENTS } from '../../consts/configConstants';
import moment from 'moment';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const Dashboard = () => {
  const history = useHistory();
  const [results, setResults] = useState([]);

  const onClickItem = (item) =>
    history.push({
      pathname: 'dashboard/deployment-details/' + item.id,
      state: {
        clientId: item.clientId,
        publicEndpoint: item.publicEndpoint
      },
      search: '?tabId=info'
    });

  const headerRow = (
    <Row className='header-row'>
      <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
        {i18next.t('PAGINATION.CLIENT_ID')}
      </Col>
      <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
        {i18next.t('PAGINATION.PUBLIC_ENDPOINT')}
      </Col>
      <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}>
        {i18next.t('PAGINATION.BASTION_HOST_IP')}
      </Col>
      <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}>
        {i18next.t('PAGINATION.LOCATION')}
      </Col>
      <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}>
        {i18next.t('PAGINATION.PLAN')}
      </Col>
      <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}>
        {i18next.t('PAGINATION.DEPLOYED_ON')}
      </Col>
    </Row>
  );

  const dataRows = results?.map((item) => (
    <Row
      onClick={() => onClickItem(item)}
      className='row-separator'
      style={{ cursor: 'pointer' }}
    >
      <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
        {item['clientId']}
      </Col>
      <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
        {item['publicEndpoint']}
      </Col>
      <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}>
        {item['bastionHostIp']}
      </Col>
      <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}>
        {item['location']}
      </Col>
      <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}>
        {item['plan']}
      </Col>
      <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}>
        {moment(item['deployedOn'])?.format('DD/MM/YYYY')}
      </Col>
    </Row>
  ));

  return (
    <CustomPagination
      resultsUrl={GET_ALL_DEPLOYMENTS}
      paginationHeading={'PAGINATION.DEPLOYMENTS_DASHBOARD'}
      showPaginationHeading={true}
      headerRow={headerRow}
      dataRows={dataRows}
      setResults={setResults}
      results={results}
      searchBarPlaceholder={i18next.t('PAGINATION.SEARCH_DEPLOYMENTS')}
      showSearchBar={true}
      paginationComponentName={'Deployments Dashboard'}
    />
  );
};

export default Dashboard;
