import { useState, useContext } from 'react';
import { Col, Row } from 'reactstrap';
import Typography from '../../components/common/_typography';
import { axiosAdapter } from '../../utils';
import { SnackbarContext } from '../../layouts/Context/snackBarContext';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import OtpInputField from '../../components/common/_otpInputField';
import { StyledButtonPrimary } from '../../components/common/_buttonNewOne';
import idConstants from '../../consts/idConstants';
import i18next from 'i18next';
import {
  CREATE_ACCOUNT,
  CREATE_OTP,
  VALIDATE_OTP
} from '../../consts/configConstants';

const MailNotification = styled.div`
  color: #f6f6f6;
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;
`;

const HighlightedText = styled.span`
  color: #41bac3;
`;

const OtpValidation = (props) => {
  const { showMessage } = useContext(SnackbarContext);
  const history = useHistory();

  const [otp, setOtp] = useState('');
  const [isOtpValid, setIsOtpValid] = useState(true);

  const handleOtpInput = (otp) => setOtp(otp);

  const checkOtpLength = () => {
    if (!idConstants.regex.otp.test(otp)) {
      setIsOtpValid(false);
      showMessage(i18next.t('MESSAGES.ERROR.ENTER_VALID_OTP'));
      return false;
    } else {
      return true;
    }
  };

  const createAccountOnOtpValidation = async (validateOtpResponse) => {
    if (validateOtpResponse.data.code === 200) {
      const createAccountResponse = await axiosAdapter(
        idConstants.axiosMethods.post,
        CREATE_ACCOUNT,
        {
          username: props.username,
          fullName: props.fullName,
          emailId: props.emailId,
          password: props.password
        }
      );
      if (createAccountResponse.data.code === 200) {
        props.handleClickHandler({
          status: true,
          remark: createAccountResponse.data.remark
        });
      } else {
        props.handleClickHandler({
          status: false,
          remark: createAccountResponse.data.remark
        });
      }
    } else {
      props.handleClickHandler({
        status: false,
        remark: validateOtpResponse.data.remark
      });
    }
  };

  const handleSubmitClick = async () => {
    setIsOtpValid(true);
    const checkOtpResponse = await checkOtpLength();

    if (checkOtpResponse === true) {
      try {
        const validateOtpResponse = await axiosAdapter(
          idConstants.axiosMethods.post,
          VALIDATE_OTP,
          {
            emailId: props.emailId,
            otp: otp,
            isNewAccount: props.isNewAccount
          }
        );
        if (validateOtpResponse.data.code === 200)
          createAccountOnOtpValidation(validateOtpResponse);
        else {
          setOtp('');
          showMessage(validateOtpResponse.data.message);
        }
      } catch (error) {
        showMessage(validateOtpResponse?.data?.message);
      }
    }
  };

  const handleResendOtpClick = async () => {
    showMessage('MESSAGES.SUCCESS.OTP_BEING_RESENT', 'success');
    try {
      const sendOtpResponse = await axiosAdapter(
        idConstants.axiosMethods.post,
        CREATE_OTP,
        {
          username: props.username,
          password: props.password,
          emailId: props.emailId,
          fullName: props.fullName,
          isNewAccount: props.isNewAccount
        }
      );
      if (sendOtpResponse.data.code === 200) {
        showMessage('MESSAGES.SUCCESS.OTP_SENT_SUCCESS', 'success');
      }
    } catch (error) {
      showMessage('MESSAGES.ERROR.OTP_SEND_FAILURE');
    }
  };
  return (
    <Row className='justify-content-center align-items-center flex-column'>
      <Col xs={12} className='landingDescription'>
        <Col>
          <MailNotification>
            {i18next.t('MESSAGES.SUCCESS.WE_HAVE_SENT_OTP')}{' '}
            <HighlightedText>{history?.location?.emailId}</HighlightedText>
          </MailNotification>
        </Col>
      </Col>
      <Col xs={12} className='inputTextStyleOtp'>
        <OtpInputField
          throwError={!isOtpValid}
          value={otp}
          onChange={handleOtpInput}
        />
      </Col>

      <Col xs={12} className='resendOtpStyle' onClick={handleResendOtpClick}>
        <Col>
          <Typography
            text={[
              i18next.t(`HELPER_TEXTS.DIDN'T_RECEIVE_OTP`),
              <b>
                <a>
                  <u>{i18next.t('HELPER_TEXTS.RESEND_OTP')}</u>
                </a>
              </b>
            ]}
            color={' #FAFAFA;'}
            tag={'p-large'}
          />
        </Col>
      </Col>
      <Col xs={12} className='otpButtonMargin'>
        <StyledButtonPrimary
          disabled={otp === ''}
          tag={'large-rounded'}
          onClick={handleSubmitClick}
          style={{ width: '100%' }}
        >
          {i18next.t('PLACEHOLDERS.CONTINUE')}
        </StyledButtonPrimary>
      </Col>
      <Col xs={12} className='otpCancelStyle'>
        <Typography
          tag={'head-x-x-small'}
          color={' #FAFAFA;'}
          text={[
            <a href='/'>
              <u>{i18next.t('PLACEHOLDERS.CANCEL')}</u>
            </a>
          ]}
        ></Typography>
      </Col>
    </Row>
  );
};

export default OtpValidation;
