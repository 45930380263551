import { createRoot } from 'react-dom/client';
import App from './App.jsx';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthProvider } from './AuthContext.js';
import { LoaderProvider } from './LoaderProvider.js';
import React from 'react';

createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <LoaderProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </LoaderProvider>
  </React.StrictMode>
);
