import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import CommonLandingPage from './CommonLandingPage';
import { axiosAdapter } from '../../utils';
import {
  Link,
  useHistory,
  useLocation
} from 'react-router-dom/cjs/react-router-dom.min';
import ChevronRight from '../../assets/images/Chevron-left.svg';
import CustomRow from '../../components/common/_customRow';
import Column from '../../components/common/_customColumn';
import styled from 'styled-components';
import ShowPasswordIcon from '../../assets/images/show_password_icon.svg';
import DontShowPasswordIcon from '../../assets/images/dont_show_password_icon.svg';
import idConstants from '../../consts/idConstants';
import i18next from 'i18next';
import { UPDATE_PASSWORD } from '../../consts/configConstants';

const Text = styled.p`
  font-family: 'Articulat CF Medium';
  margin: 0;
`;

const InputField = styled.input`
  font-family: 'Articulat CF Medium';
  font-size: 18px;
  font-weight: 500;
  color: #f6f6f6;
  background-color: inherit;
  outline: none;
  border: none;
  width: 100%;
  &::placeholder {
    color: #777777;
  }
`;

const InputFieldContainer = styled.div`
  padding: 12px;
  width: 48px;
  height: 48px;
  border-radius: 12px;
  background-color: #2f2f2f;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 1px solid #2f2f2f;
`;

const StyledButton = styled.button`
  border-radius: 12px;
  background-color: #47ccd6;
  border: 1px solid #47ccd6;
  padding: 13px;
  color: #ececec;
  font-family: 'Articulat CF Medium';
  font-size: 18px;
  font-weight: 500;
  width: 100%;
  min-height: 56px;
  &:disabled {
    opacity: 0.5;
  }
`;

const ResetPassword = () => {
  const location = useLocation();
  const history = useHistory();
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [isResetLoading, setIsResetLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);

  const checkPassword = () => {
    if (idConstants.regex.password.test(password1) === false) {
      setIsPasswordInvalid(true);
      setErrorMessage('VALIDATION.PASSWORD');
      return false;
    } else {
      return true;
    }
  };

  const handleSubmitClick = async () => {
    let comparePassword;
    setIsPasswordInvalid(false);
    setIsResetLoading(true);

    //Input Fields Validation
    let checkPasswordResponse = await checkPassword();

    if (checkPasswordResponse === true) {
      //This comparePassword will return 0 (if passwords match), else -1 will be returned.
      comparePassword = password1.localeCompare(password2);
      if (comparePassword !== 0) {
        comparePassword = false;
        setIsPasswordInvalid(true);
        setErrorMessage(`VALIDATION.PASSWORDS_DON'T_MATCH`);
      } else {
        comparePassword = true;
      }
    }

    if (checkPasswordResponse === true && comparePassword === true) {
      try {
        await axiosAdapter(idConstants.axiosMethods.post, UPDATE_PASSWORD, {
          password: password1,
          emailId: location.state?.emailId
        });
        setIsResetLoading(false);
        history.push(idConstants.path.login);
      } catch (err) {
        setIsResetLoading(false);
        if (err?.response?.data?.code === 400) {
          setErrorMessage(err?.response?.data.remarks);
        } else {
          setErrorMessage('MESSAGES.ERROR.UNABLE_TO_RESET');
        }
      }
    }
    setIsResetLoading(false);
  };

  return (
    <CommonLandingPage>
      <CustomRow>
        <Column style={{ marginBottom: '40px' }}>
          <Text
            style={{ fontSize: '22px', lineHeight: '31.2px', color: '#F6F6F6' }}
          >
            {i18next.t('HELPER_TEXTS.SET_NEW_PASSWORD')}
          </Text>
        </Column>
        <Column style={{ marginBottom: '14px' }}>
          <InputFieldContainer
            style={isPasswordInvalid ? { borderColor: '#EA3A3A' } : {}}
          >
            <InputField
              placeholder={i18next.t('PLACEHOLDERS.PASSWORD')}
              type={showPassword1 ? 'text' : 'password'}
              onChange={(e) => setPassword1(e.target.value)}
              value={password1}
            />
            <img
              src={showPassword1 ? DontShowPasswordIcon : ShowPasswordIcon}
              alt={i18next.t('PLACEHOLDERS.SHOW_PASSWORD')}
              onClick={() => {
                setShowPassword1(!showPassword1);
              }}
              style={{
                cursor: 'pointer',
                marginLeft: '5px',
                height: '18px',
                width: '18px'
              }}
            />
          </InputFieldContainer>
        </Column>
        <Column style={{ marginBottom: errorMessage ? '10px' : '40px' }}>
          <InputFieldContainer
            style={isPasswordInvalid ? { borderColor: '#EA3A3A' } : {}}
          >
            <InputField
              placeholder={i18next.t('PLACEHOLDERS.CONFIRM_PASSWORD')}
              type={showPassword2 ? 'text' : 'password'}
              onChange={(e) => setPassword2(e.target.value)}
              value={password2}
            />
            <img
              src={showPassword2 ? DontShowPasswordIcon : ShowPasswordIcon}
              alt={i18next.t('PLACEHOLDERS.SHOW_PASSWORD')}
              onClick={() => {
                setShowPassword2(!showPassword2);
              }}
              style={{
                cursor: 'pointer',
                marginLeft: '5px',
                height: '18px',
                width: '18px'
              }}
            />
          </InputFieldContainer>
        </Column>
        {errorMessage && (
          <Column style={{ marginBottom: '12px' }}>
            <Text style={{ color: '#EA3A3A', fontSize: '12px' }}>
              {i18next.t(errorMessage)}
            </Text>
          </Column>
        )}
        <Column style={{ marginBottom: '34px' }}>
          <StyledButton
            onClick={handleSubmitClick}
            disabled={!password1 || !password2 || isResetLoading}
          >
            {i18next.t('HELPER_TEXTS.RESET_PASSWORD')}
          </StyledButton>
        </Column>
        <Column>
          <Link to={idConstants.path.login}>
            <Text style={{ color: '#CCCCCC', fontSize: '16px' }}>
              <img
                src={ChevronRight}
                alt={i18next.t('PLACEHOLDERS.CHEVRON_ALT')}
                style={{ height: '12px', width: '12px', marginRight: '10px' }}
              />
              {i18next.t('HELPER_TEXTS.BACK_TO_LOGIN')}
            </Text>
          </Link>
        </Column>
      </CustomRow>
    </CommonLandingPage>
  );
};

export default withRouter(ResetPassword);
