import CreateAccount from '../pages/LandingPages/CreateAccount';
import Login from '../pages/LandingPages/Login';
import SupportPage from '../pages/LandingPages/SupportPage';
import NewUserOtpVerification from '../pages/LandingPages/NewUserOtpVerification';
import Dashboard from '../pages/Dashboard/Dashboard';
import ForgotPassword from '../pages/LandingPages/ForgotPassword';
import ForgotPasswordOtpVerification from '../pages/LandingPages/ForgotPasswordOtpVerification';
import ResetPassword from '../pages/LandingPages/ResetPassword';
import PageNotFound from '../pages/PageNotFound';
import idConstants from '../consts/idConstants';
import DeploymentDetails from '../pages/Dashboard/DeploymentDetails';

const publicRoutes = [
  {
    path: idConstants.path.login,
    exact: true,
    component: Login
  },
  {
    path: idConstants.path.createAccount,
    exact: true,
    component: CreateAccount
  },
  {
    path: idConstants.path.newUserOtpVerification,
    exact: true,
    component: NewUserOtpVerification
  },
  {
    path: idConstants.path.support,
    exact: true,
    component: SupportPage
  },
  {
    path: idConstants.path.forgotPassword,
    exact: true,
    component: ForgotPassword
  },
  {
    path: idConstants.path.forgotPasswordOtpVerification,
    exact: true,
    component: ForgotPasswordOtpVerification
  },
  {
    path: idConstants.path.resetPassword,
    exact: true,
    component: ResetPassword
  },
  {
    path: idConstants.path.pageNotFound,
    exact: true,
    component: PageNotFound
  },
  //NOTE: This route should be at the end of all other routes.
  {
    path: '/',
    exact: true,
    component: Login
  }
];

const authProtectedRoutes = [
  {
    path: idConstants.path.dashboard,
    exact: true,
    component: Dashboard
  },
  {
    path: idConstants.path.deploymentDetails,
    exact: true,
    component: DeploymentDetails
  },
  {
    path: idConstants.path.pageNotFound,
    exact: true,
    component: PageNotFound
  }
];

export { publicRoutes, authProtectedRoutes };
