import React, { createContext, useContext, useState } from 'react';
import Loader from './components/common/_loader';

// Create context for loader state
const LoaderContext = createContext();

export const useLoader = () => useContext(LoaderContext);

export const LoaderProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <LoaderContext.Provider
      value={{
        isLoading,
        showLoader: () => setIsLoading(true),
        hideLoader: () => setIsLoading(false)
      }}
    >
      {children}
      {isLoading && <Loader />}
    </LoaderContext.Provider>
  );
};
