import styled from 'styled-components';

let buttonWidth;

const StyledButtonPrimary = styled.button`
  all: unset;
  @media (max-width: 460px) {
    width: 150px;
  }
  @media (min-width: 460px) {
    width: ${(props) => (props.width ? props.width : buttonWidth)};
  }
  height: 56px;
  background: ${(props) => (props.disabled ? '#2A5F63' : '#47CCD6')};
  font-family: 'Articulat CF Medium';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #171717;
`;

const StyledRoundedButtonPrimary = styled.button`
  all: unset;
  border-radius: 12px;
  width: 100%;
  background: ${(props) => (props.disabled ? '#2A5F63' : '#47CCD6')};
  font-size: 18px;
  font-weight: 500;
  color: #f6f6f6;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 12px 40px;
  display: flex;
`;

const StyledButtonSecondary = styled.button`
  all: unset;
  @media (max-width: 460px) {
    width: 150px;
  }
  @media (min-width: 460px) {
    width: ${(props) => (props.width ? props.width : buttonWidth)};
  }
  color: #fafafa;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  font-family: 'Articulat CF Medium';
  font-style: normal;
  font-size: 22px;
  font-weight: 600;
  width: ${(props) => (props.width ? props.width : buttonWidth)};
  height: 56px;
  text-align: center;
  border: 2px solid ${(props) => (props.disabled ? '#797979' : '#FAFAFA')};
`;

const Button = (props) => {
  const { width, disabled, tag, btnText } = props;

  const handleButtonClick = () => {
    const onClickEvent = props.onClickEvent;
    onClickEvent();
  };

  switch (props.tag) {
    case 'large-primary':
      buttonWidth = '408px';
      break;
    case 'primary':
      buttonWidth = '240px';
      break;
    case 'small-primary':
      buttonWidth = '170px';
      break;
    case 'large-secondary':
      buttonWidth = '408px';
      break;
    case 'secondary':
      buttonWidth = '240px';
      break;
    case 'small-secondary':
      buttonWidth = '170px';
      break;
    case 'large-rounded':
      buttonWidth = '100%';
      break;
    default:
      buttonWidth = '240px';
      break;
  }

  return (
    <>
      {tag.includes('primary') && (
        <StyledButtonPrimary
          disabled={disabled ? disabled : false}
          width={width}
          onClick={() => handleButtonClick()}
        >
          {btnText}
        </StyledButtonPrimary>
      )}

      {tag.includes('secondary') && (
        <StyledButtonSecondary
          disabled={disabled ? disabled : false}
          width={width}
          onClick={() => handleButtonClick()}
        >
          {btnText}
        </StyledButtonSecondary>
      )}
      {tag.includes('large-rounded') && (
        <StyledRoundedButtonPrimary
          disabled={disabled ? disabled : false}
          width={width}
          onClick={() => handleButtonClick()}
        >
          {btnText}
        </StyledRoundedButtonPrimary>
      )}
    </>
  );
};

export default Button;
