import { Col, Row } from 'reactstrap';
import { StyledButtonPrimary } from '../../components/common/_buttonNewOne';
import Typography from '../../components/common/_typography';

const ButtonLandingPage = (props) => {
  const handleSubmitClick = () => {
    props.onClickEvent();
  };
  return (
    <Row style={{ marginTop: '40px', gap: '1rem' }}>
      <Col xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <StyledButtonPrimary
          tag={'large-rounded'}
          disabled={props.disabled}
          onClick={handleSubmitClick}
          style={{ width: '100%' }}
        >
          {props.buttonPlaceholder}
        </StyledButtonPrimary>
      </Col>
      <Col xs={12}>
        <Typography
          tag={'p-bold-medium'}
          color={' #FAFAFA;'}
          text={props.linkLable}
        />
      </Col>
    </Row>
  );
};

export default ButtonLandingPage;
