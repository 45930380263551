import { useHistory, withRouter } from 'react-router-dom';
import { Col } from 'reactstrap';
import React, { useContext, useState } from 'react';
import CommonLandingPage from './CommonLandingPage';
import { axiosAdapter } from '../../utils';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import CustomRow from '../../components/common/_customRow';
import ShowPasswordIcon from '../../assets/images/show_password_icon.svg';
import DontShowPasswordIcon from '../../assets/images/dont_show_password_icon.svg';
import Column from '../../components/common/_customColumn';
import { StyledButtonPrimary } from '../../components/common/_buttonNewOne';
import i18next from 'i18next';
import idConstants from '../../consts/idConstants';
import { AuthContext } from '../../AuthContext';
import { SnackbarContext } from '../../layouts/Context/snackBarContext';
import { LOGIN } from '../../consts/configConstants';

const Text = styled.p`
  font-family: 'Articulat CF Medium';
  margin: 0;
`;

const InputField = styled.input`
  font-family: 'Articulat CF Medium';
  font-size: 16px;
  font-weight: 500;
  color: #fafafa;
  background-color: inherit;
  outline: none;
  border: none;
  width: 100%;
  caret-color: white;
  &::placeholder {
    color: #b0b0b0;
  }
`;

const InputFieldContainer = styled.div`
  padding: 11px 12px;
  width: 100%;
  border-radius: 12px;
  border: none;
  background-color: #2f2f2f;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #2f2f2f;
`;

const Login = () => {
  const { login } = useContext(AuthContext);
  const { showMessage } = useContext(SnackbarContext);

  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const checkUserName = () => {
    if (
      idConstants.regex.username1.test(username) === false ||
      idConstants.regex.username2.test(username) === true
    ) {
      setErrorMessage(i18next.t('VALIDATION.USERNAME'));
      return false;
    } else {
      return true;
    }
  };

  const checkPassword = () => {
    if (idConstants.regex.password.test(password) === false) {
      setErrorMessage(i18next.t('VALIDATION.PASSWORD'));
      return false;
    } else {
      return true;
    }
  };

  const onClickEvent = async () => {
    let checkPasswordResponse;
    setErrorMessage('');

    let checkUserNameResponse = await checkUserName();

    if (checkUserNameResponse === true) {
      checkPasswordResponse = await checkPassword();
    }

    if (checkUserNameResponse === true && checkPasswordResponse === true) {
      try {
        const loginResponse = await axiosAdapter(
          idConstants.axiosMethods.post,
          LOGIN,
          {
            username,
            password
          }
        );
        if (loginResponse.data.code === 200) login(loginResponse.data.token);
        else showMessage(loginResponse.data.message);
      } catch (err) {
        if (err.response?.data?.remarks) {
          showMessage(err.response.data.remarks);
        } else {
          showMessage(i18next.t('HELPER_TEXTS.UNABLE_TO_LOGIN'));
        }
      }
    }
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  return (
    <CommonLandingPage>
      <Col>
        <CustomRow>
          <Column style={{ marginBottom: '40px' }}>
            <Text
              style={{ color: '#F6F6F6', fontSize: '22px', lineHeight: '31px' }}
            >
              {i18next.t('HELPER_TEXTS.WELCOME_BACK')}
            </Text>
          </Column>
          <Column style={{ marginBottom: '16px' }}>
            <InputFieldContainer
              style={errorMessage ? { borderColor: '#EA3A3A' } : {}}
            >
              <InputField
                placeholder={i18next.t('PLACEHOLDERS.USERNAME')}
                name='username'
                onChange={handleUsernameChange}
              />
            </InputFieldContainer>
          </Column>
          <Column style={{ marginBottom: errorMessage ? '10px' : '40px' }}>
            <InputFieldContainer
              style={errorMessage ? { borderColor: '#EA3A3A' } : {}}
            >
              <InputField
                placeholder={i18next.t('PLACEHOLDERS.PASSWORD')}
                name='password'
                type={showPassword ? 'text' : 'password'}
                onChange={handlePasswordChange}
              />
              <img
                src={showPassword ? DontShowPasswordIcon : ShowPasswordIcon}
                alt={i18next.t('PLACEHOLDERS.SHOW_PASSWORD_ALT')}
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
                style={{
                  cursor: 'pointer',
                  marginLeft: '5px',
                  height: '18px',
                  width: '18px'
                }}
              />
            </InputFieldContainer>
          </Column>
          {errorMessage && (
            <Column style={{ marginBottom: '12px' }}>
              <Text style={{ color: '#EA3A3A', fontSize: '12px' }}>
                {errorMessage}
              </Text>
            </Column>
          )}
          <Column style={{ marginBottom: '16px' }}>
            <StyledButtonPrimary
              style={{ fontFamily: 'Articulat CF Medium', width: '100%' }}
              onClick={onClickEvent}
              disabled={!username || !password}
            >
              {i18next.t('HELPER_TEXTS.SIGN_IN')}
            </StyledButtonPrimary>
          </Column>
          <Column>
            <Link to={idConstants.path.forgotPassword}>
              <Text
                style={{
                  fontSize: '16px',
                  lineHeight: '21px',
                  fontWeight: '600',
                  textDecoration: 'underline',
                  color: '#CCCCCC',
                  cursor: 'pointer'
                }}
              >
                {i18next.t('PLACEHOLDERS.FORGOT_PASSWORD')}
              </Text>
            </Link>
          </Column>
          <Column>
            <Link to={idConstants.path.createAccount}>
              <Text
                style={{
                  fontSize: '16px',
                  paddingTop: '16px',
                  lineHeight: '21px',
                  fontWeight: '600',
                  textDecoration: 'underline',
                  color: '#CCCCCC',
                  cursor: 'pointer'
                }}
              >
                {i18next.t('HELPER_TEXTS.CREATE_NEW_USER')}
              </Text>
            </Link>
          </Column>
        </CustomRow>
      </Col>
    </CommonLandingPage>
  );
};

export default withRouter(Login);
