import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import condenseLogo from '../../assets/images/condenseLogo.svg';
// import landingImg from '../../assets/images/landingpage_logo_new.svg';
import CustomRow from '../../components/common/_customRow';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import i18next from 'i18next';
import idConstants from '../../consts/idConstants';

const Text = styled.p`
  font-family: 'Articulat CF Medium';
  margin: 0;
`;

const CommonLandingPage = (props) => {
  const history = useHistory();
  return (
    <Container fluid className='landingScreenBackground'>
      <Row>
        <Col xs={12} lg={7} className='landingImageAlign'>
          {/* <img
            src={landingImg}
            alt={i18next.t('PLACEHOLDERS.LANDING_PAGE_ALT')}
            className='landingPageImage'
          /> */}
        </Col>
        <Col xs={12} lg={5} className='landingSideBar'>
          <Row className='landingLeftSideRow'>
            <Col xs={12}>
              <div className='landingImage'>
                <img
                  src={condenseLogo}
                  className='logoStyle'
                  alt={i18next.t('PLACEHOLDERS.CONDENSE_LOGO_ALT')}
                />
              </div>
            </Col>
            <Col xs={12} className='flexCenter'>
              {props.children}
            </Col>
          </Row>
          <CustomRow
            style={{
              flexWrap: 'nowrap',
              fontSize: '12px',
              marginBottom: '10px',
              padding: '0px 16px'
            }}
          >
            <Text style={{ color: '#B0B0B0' }}>
              {i18next.t('PLACEHOLDERS.NEED_MORE_HELP')}{' '}
              <span
                style={{
                  color: '#CCCCCC',
                  fontWeight: '600',
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }}
                onClick={() => history.push(idConstants.path.support)}
              >
                {i18next.t('PLACEHOLDERS.CONTACT_SUPPORT')}
              </span>
            </Text>
            <Text style={{ color: '#CCCCCC' }}>
              {i18next.t('ALL_RIGHTS_RESERVED')}
            </Text>
          </CustomRow>
        </Col>
      </Row>
    </Container>
  );
};

export default CommonLandingPage;
