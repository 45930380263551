import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { SnackbarProvider } from '../layouts/Context/snackBarContext';
import TopSnackBar from '../components/common/_topSnackBar';
import { AuthContext } from '../AuthContext';
import NavBar from '../components/NavBar/NavBar';
import idConstants from '../consts/idConstants';
import DeploymentDetails from '../pages/Dashboard/DeploymentDetails';
import { containsPath } from '../utils';

const AppRoute = ({ component: Component, isAuthProtected, ...rest }) => {
  const { logout, isTokenPresent, expiryTime } = useContext(AuthContext);
  const currentTime = Math.floor(Date.now() / 1000);

  const { computedMatch, location } = rest;

  if (location.pathname !== idConstants.path.pageNotFound) {
    if (
      computedMatch.path !== location.pathname &&
      !containsPath(idConstants.path.deploymentDetails, location.pathname, '/')
    )
      return <Redirect to={{ pathname: idConstants.path.pageNotFound }} />;

    if (!isTokenPresent && isAuthProtected) {
      return <Redirect to={{ pathname: '/' }} />;
    }

    if (isAuthProtected && expiryTime && currentTime > expiryTime) {
      logout();
      return <Redirect to={{ pathname: '/' }} />;
    } else if (!isAuthProtected && expiryTime && currentTime < expiryTime) {
      return <Redirect to={{ pathname: idConstants.path.dashboard }} />;
    } else if (
      containsPath(
        idConstants.path.deploymentDetails,
        location.pathname,
        ':'
      ) &&
      isAuthProtected &&
      expiryTime &&
      currentTime < expiryTime
    ) {
      return (
        <>
          <SnackbarProvider>
            <TopSnackBar />
            <NavBar />
            <Route
              path={idConstants.path.deploymentDetails}
              component={DeploymentDetails}
            />
          </SnackbarProvider>
        </>
      );
    }
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthProtected) {
          return (
            <SnackbarProvider>
              <TopSnackBar />
              {location.pathname !== idConstants.path.pageNotFound && (
                <NavBar />
              )}
              <Component {...props} />
            </SnackbarProvider>
          );
        } else {
          return (
            <SnackbarProvider>
              <TopSnackBar />
              <Component {...props} />
            </SnackbarProvider>
          );
        }
      }}
    />
  );
};

export default AppRoute;
