import styled from 'styled-components';

let buttonWidth;

export const StyledButtonPrimary = styled.button`
  all: unset;
  @media (max-width: 460px) {
    width: 150px;
  }
  @media (min-width: 460px) {
    width: ${(props) => (props.width ? props.width : buttonWidth)};
  }
  height: 3.33rem;
  /* width: 100%; */
  background: ${(props) => (props.disabled ? '#5E5E5E' : '#47CCD6')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  font-family: 'Articulat CF Medium';
  font-style: normal;
  border-radius: 0.84rem;
  font-size: 1.112rem;
  line-height: 0.8rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #f6f6f6;
  letter-spacing: 0.5px;
  font-weight: bold;

  &:hover {
    background: ${(props) => (props.disabled ? '#5E5E5E' : '#40909a')};
    /* background: #40909a; */
  }
  &:active {
    background: ${(props) => (props.disabled ? '#5E5E5E' : '#47ccd6')};
    /* background: #47ccd6; */
    box-shadow: 0px 0.55rem 1.112rem 0px rgba(0, 0, 0, 0.3) inset;
  }
`;

export const StyledRoundedButtonPrimary = styled.button`
  all: unset;
  border-radius: 12px;
  width: 100%;
  background: ${(props) => (props.disabled ? '#2A5F63' : '#47CCD6')};
  font-size: 18px;
  font-weight: 500;
  color: #f6f6f6;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 12px 40px;
  display: flex;
`;

export const StyledButtonSecondary = styled.button`
  all: unset;
  @media (max-width: 460px) {
    width: 150px;
  }
  @media (min-width: 460px) {
    width: ${(props) => (props.width ? props.width : buttonWidth)};
  }
  color: #f6f6f6;
  /* border-color: "2px solid #f6f6f6"; */
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  font-family: 'Articulat CF Medium';
  letter-spacing: 0.5px;
  font-weight: bold;
  /* font-style: normal; */
  font-size: 1.112rem;
  border-radius: 0.84rem;
  /* width: ${(props) => (props.width ? props.width : buttonWidth)}; */
  height: 3.33rem;
  text-align: center;
  border: 0.1rem solid ${(props) => (props.disabled ? '#797979' : '#FAFAFA')};
  &:hover {
    background: transparent;
    border: 0.1rem solid #47ccd6;
    color: #47ccd6;
  }
  &:active {
    background: #1e565a;
    box-shadow: 0px 0.55rem 1.112rem 0px rgba(0, 0, 0, 0.3) inset;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
export const StyledDeleteButton = styled.button`
  all: unset;
  @media (max-width: 460px) {
    width: 150px;
  }
  @media (min-width: 460px) {
    width: ${(props) => (props.width ? props.width : buttonWidth)};
  }
  height: 3.33rem;
  /* width: 100%; */
  background: ${(props) => (props.disabled ? '#6a7171' : '#EA3A3A')};

  font-family: 'Articulat CF Medium';
  font-style: normal;
  border-radius: 0.84rem;
  font-size: 1.112rem;
  line-height: 1.112rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #f6f6f6;
  letter-spacing: 0.5px;
  font-weight: bold;
  &:hover {
    background: #993a3a;
  }
  &:active {
    background: #ea3a3a;
    box-shadow: 0px 0.55rem 1.112rem 0px rgba(0, 0, 0, 0.3) inset;
  }
`;

export const StyledPrimaryButton2 = styled.button`
  all: unset;
  @media (max-width: 460px) {
    width: 150px;
  }
  @media (min-width: 460px) {
    width: ${(props) => (props.width ? props.width : buttonWidth)};
  }
  height: 3.33rem;
  /* width: 100%; */
  background: ${(props) => (props.disabled ? '#6a7171' : '#EA3A3A')};
  font-family: 'Articulat CF Medium';
  font-style: normal;
  border-radius: 0.84rem;
  font-size: 1.112rem;
  line-height: 1.112rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #f6f6f6;
  letter-spacing: 0.5px;
  font-weight: bold;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  &:hover {
    background: ${(props) => (props.disabled ? '#6a7171' : '#993a3a')};
  }
  &:active {
    background: #ea3a3a;
    box-shadow: 0px 0.55rem 1.112rem 0px rgba(0, 0, 0, 0.3) inset;
  }
`;

export const StyledButtonVersion = styled.button`
  all: unset;
  // height: 1.25rem;
  color: ${(props) => props.color || 'green'};
  border: ${(props) => props.border || '1px solid green'};
  font-family: 'Articulat CF Medium';
  font-style: normal;
  border-radius: 0.4rem;
  padding: ${(props) => props.padding || '0.35rem 0.5rem'};
  font-size: 0.6rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: 'Articulat CF Medium';
  &:active {
    box-shadow: ${(props) =>
      props.disabled
        ? 'none'
        : '0px 0.55rem 1.112rem 0px rgba(0, 0, 0, 0.3) inset'};
  }
`;

export const Button = (props) => {
  const { width, disabled, tag, btnText } = props;

  const handleButtonClick = () => {
    const onClickEvent = props.onClickEvent;
    onClickEvent();
  };

  switch (props.tag) {
    case 'large-primary':
      buttonWidth = '408px';
      break;
    case 'primary':
      buttonWidth = '240px';
      break;
    case 'small-primary':
      buttonWidth = '170px';
      break;
    case 'large-secondary':
      buttonWidth = '408px';
      break;
    case 'secondary':
      buttonWidth = '240px';
      break;
    case 'small-secondary':
      buttonWidth = '170px';
      break;
    case 'large-rounded':
      buttonWidth = '100%';
      break;
    default:
      buttonWidth = '240px';
      break;
  }

  return (
    <>
      {tag.includes('primary') && (
        <StyledButtonPrimary
          disabled={disabled ? disabled : false}
          width={width}
          onClick={() => handleButtonClick()}
        >
          {btnText}
        </StyledButtonPrimary>
      )}

      {tag.includes('secondary') && (
        <StyledButtonSecondary
          disabled={disabled ? disabled : false}
          width={width}
          onClick={() => handleButtonClick()}
        >
          {btnText}
        </StyledButtonSecondary>
      )}
      {tag.includes('large-rounded') && (
        <StyledRoundedButtonPrimary
          disabled={disabled ? disabled : false}
          width={width}
          onClick={() => handleButtonClick()}
        >
          {btnText}
        </StyledRoundedButtonPrimary>
      )}
    </>
  );
};

// export default Button;
