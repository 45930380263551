import { env } from '../env';

export const REACT_APP_CLIENT_ID = env.REACT_APP_CLIENT_ID;
export const BASE_URL_MINUS_VERSION = env.REACT_APP_URL;
console.log(env,"env -----");
export const SERVICE_VERSION_V1 = 'admin/v1';
export const BASE_URL = `${BASE_URL_MINUS_VERSION}${SERVICE_VERSION_V1}`;
export const CREATE_ACCOUNT = '/createAccount';
export const CREATE_OTP = '/createOTP';
export const VALIDATE_OTP = '/validateOTP';
export const LOGIN = '/login';
export const UPDATE_PASSWORD = '/updatePassword';
export const GET_ALL_DEPLOYMENTS = '/getAllDeployments';
export const GET_ALL_CLIENT_SERVICES = '/getAllClientServices';
export const GET_SERVICE_DETAILS = '/getServiceDetails';
export const UPDATE_SERVICE_DEPLOYMENT = '/updateServiceDeployment';
export const GET_DEPLOYMENT_DETAILS = '/getDeploymentDetail';
