import React, { useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import CommonValidationStatus from './CommonValidationStatus';
import CommonLandingPage from './CommonLandingPage';
import OtpValidation from './OtpValidation';
import i18next from 'i18next';
import idConstants from '../../consts/idConstants';

const NewUserOtpVerification = (props) => {
  const history = useHistory();

  const [isOtpValidated, setIsOtpValidated] = useState(null);
  const [message, setMessage] = useState('');

  const validOtpValidationHandler = (props) => {
    if (isOtpValidated != null) {
      setIsOtpValidated(!setIsOtpValidated);
    } else {
      setIsOtpValidated(props.status);
      setMessage(props.remark);
    }
  };

  const handleLoginButtonClick = () => {
    history.push(idConstants.path.login);
  };

  const handleTryAgainButtonClick = () => {
    history.push(idConstants.path.createAccount);
  };

  return (
    <CommonLandingPage>
      {isOtpValidated == null && (
        <OtpValidation
          handleClickHandler={validOtpValidationHandler}
          title={i18next.t('CONDENSE_X')}
          description={i18next.t('ONE_LAST_STEP')}
          placeholder={i18next.t('ENTER_OTP_HERE')}
          username={props.location.username}
          password={props.location.password}
          emailId={props.location.emailId}
          fullName={props.location.fullName}
          isNewAccount={props.location.isNewAccount}
        />
      )}
      {isOtpValidated === true && (
        <CommonValidationStatus
          title={i18next.t('ACCOUNT_CREATED_TITLE')}
          description={i18next.t('ACCOUNT_CREATED_DESC')}
          status={'success'}
          buttonPlaceholder={i18next.t('PLACEHOLDERS.LOGIN')}
          handleButtonClick={handleLoginButtonClick}
          helperTextRoute={idConstants.path.support}
          helperText={i18next.t('HELPER_TEXTS.VIEW_DOCS')}
        />
      )}

      {isOtpValidated === false && (
        <CommonValidationStatus
          title={i18next.t('MESSAGES.ERROR.PROBLEM_CREATING_ACCOUNT_TITLE')}
          description={
            message === ''
              ? i18next.t('MESSAGES.ERROR.PROBLEM_CREATING_ACCOUNT_TITLE_DESC')
              : message
          }
          status={'fail'}
          buttonPlaceholder={i18next.t('PLACEHOLDERS.TRY_AGAIN')}
          handleButtonClick={handleTryAgainButtonClick}
          helperTextRoute={'/'}
          helperText={i18next.t('HELPER_TEXTS.GO_TO_HOME')}
        />
      )}
    </CommonLandingPage>
  );
};

export default withRouter(NewUserOtpVerification);
