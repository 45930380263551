import i18next from 'i18next';
import React, { useState } from 'react';

const SnackbarContext = React.createContext({
  message: '',
  showMessage: () => {}
});

const SnackbarProvider = ({ children }) => {
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  const showMessage = (newMessage, type) => {
    setMessage(i18next.t(newMessage));
    setMessageType(type);
  };

  return (
    <SnackbarContext.Provider value={{ message, showMessage, messageType }}>
      {children}
    </SnackbarContext.Provider>
  );
};

export { SnackbarProvider, SnackbarContext };
