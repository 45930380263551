import { Row, Container, Col } from 'reactstrap';
import PageNotFoundImg from '../assets/images/PageNotFound.png';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { StyledButtonPrimary } from '../components/common/_buttonNewOne';
import i18next from 'i18next';
import idConstants from '../consts/idConstants';

const PageNotFound = () => {
  const buttonLabel = localStorage.token
    ? 'HELPER_TEXTS.GO_TO_HOME'
    : 'HELPER_TEXTS.GO_TO_LOGIN';
  return (
    <Container fluid>
      <Row xs={12}>
        <Container xs={12} className='supportScreenBackground'>
          <Col
            xs={12}
            style={{
              marginTop: '50px',
              textAlign: 'center',
              justifyItems: 'center',
              height: '100vh'
            }}
          >
            <img
              style={{
                width: '800px'
              }}
              src={PageNotFoundImg}
            />
            <Col style={{ margin: '50px 0px 0px 0px' }}>
              <Link to={idConstants.path.login}>
                <StyledButtonPrimary
                  style={{
                    fontFamily: 'Articulat CF Medium',
                    width: '15%',
                    background: '#248FFF',
                    color: '#000'
                  }}
                >
                  {i18next.t(buttonLabel)}
                </StyledButtonPrimary>
              </Link>
            </Col>
          </Col>
        </Container>
      </Row>
    </Container>
  );
};

export default PageNotFound;
