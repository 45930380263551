import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import leftArrow from '../../assets/images/leftArrow.png';
import '../../scss/main.scss';
import i18next from 'i18next';

const StyledBackButtonText = styled.div`
  margin-top: 13px;
  font-family: 'Articulat CF Medium';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 100%;
  color: #fafafa;
  display: flex;
  align-items: center;
  text-align: center;
  /* &:hover {
    background: #40909a;
    border-color: #40909a;
  } */
`;

const StyledBackButton = styled.div`
  all: unset;
  cursor: pointer;
  box-sizing: border-box;
  width: 194px;
  height: 48px;
  left: 88px;
  top: 48px;
  border: 2px solid #fafafa;
`;

const BackButton = (props) => {
  const handleButtonClick = () => {
    const onClickEvent = props.onClickEvent;
    onClickEvent();
  };

  return (
    <StyledBackButton
      disabled={props.disabled ? props.disabled : false}
      onClick={() => handleButtonClick()}
    >
      <Row>
        <Col className='backButtonStyle'>
          <Row>
            <Col xs={2} className='backButtonArrowStyle'>
              <img
                src={leftArrow}
                alt={i18next.t('PLACEHOLDERS.LEFT_ARROW_ALT')}
                width='30px'
                height='28px'
              />
            </Col>
            <Col xs={8}>
              <StyledBackButtonText>
                {i18next.t('PLACEHOLDERS.GO_BACK')}
              </StyledBackButtonText>
            </Col>
          </Row>
        </Col>
      </Row>
    </StyledBackButton>
  );
};

export default BackButton;
