import i18next from 'i18next';
import translationEN from './assets/i18next/en.json';

const resources = {
  en: {
    translation: translationEN
  }
};

i18next.init({
  load: 'languageOnly',
  saveMissingPlurals: false,
  fallbackLng: 'en',
  debug: false,
  useSuspense: true,
  resources,
  detection: {
    order: ['path', 'localStorage', 'navigator', 'htmlTag'],
    caches: ['localStorage']
  },
  initImmediate: false,
  ready: true
});

export default i18next;
