import axios from 'axios';
import decode from 'jwt-decode';
import { BASE_URL } from './consts/configConstants';
import i18next from 'i18next';

export const axiosAdapter = async (method, endPoint, data, isAbsolute) => {
  let getTokenFromStorage = localStorage.getItem('token');
  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('loginId');
    localStorage.removeItem('userName');
  };
  if (getTokenFromStorage) {
    const { exp } = decode(getTokenFromStorage);
    if (Date.now() / 1000 > exp) {
      logout();
      if (!localStorage.getItem('token')) {
        window.location.reload();
      }
      throw new Error(i18next.t('MESSAGES.ERROR.TOKEN_EXPIRED'));
    }
  }

  const config = {
    method: method,
    url: !isAbsolute ? `${BASE_URL}${endPoint}` : endPoint,
    // withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getTokenFromStorage}`
    },
    ...(data && { data })
  };
  let axiosResponse;
  try {
    axiosResponse = await axios(config);
  } catch (error) {
    axiosResponse = error?.response;
  }
  return axiosResponse;
};

export const remToPx = (rem) => {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
};

export const containsPath = (pathToCheck, containerPath, id) => {
  return containerPath.includes(
    pathToCheck.substring(0, pathToCheck.lastIndexOf(id))
  );
};

export const compareVersions = (version1, version2) => {
  if (version1 && version2) {
    const v1 = version1?.split('.').map(Number); // Split and convert to numbers
    const v2 = version2?.split('.').map(Number);

    for (let i = 0; i < 3; i++) {
      if (v1[i] > v2[i]) return 1; // version1 is greater
      if (v1[i] < v2[i]) return -1; // version1 is smaller
    }

    return 0; // Both versions are equal
  }
};

export const alertsDashboardData = () => [
  {
    sNo: '01',
    alertName: 'KubePersistentVolFillingUp',
    severity: 'High',
    summary: 'kube-state-metrics is experiencing errors in list operations',
    startTime: new Date(),
    url: 'https://ncvocj.condense.zeliot.in/'
  },
  {
    sNo: '02',
    alertName: 'DevCPUThrottleHigh',
    severity: 'Medium',
    summary: 'kube-state-metrics is experiencing errors in list operations',
    startTime: new Date(),
    url: 'https://ncvocj.condense.zeliot.in/'
  },
  {
    sNo: '03',
    alertName: 'KubeStateMetricsListingError',
    severity: 'Low',
    summary: 'kube-state-metrics is experiencing errors in list operations',
    startTime: new Date(),
    url: 'https://ncvocj.condense.zeliot.in/'
  },
  {
    sNo: '04',
    alertName: 'DevCPUThrottleHigh',
    severity: 'Null',
    summary: 'kube-state-metrics is experiencing errors in list operations',
    startTime: new Date(),
    url: 'https://ncvocj.condense.zeliot.in/'
  },
  {
    sNo: '05',
    alertName: 'DevCPUThrottleHigh',
    severity: 'Critical',
    summary: 'kube-state-metrics is experiencing errors in list operations',
    startTime: new Date(),
    url: 'https://ncvocj.condense.zeliot.in/'
  },
  {
    sNo: '06',
    alertName: 'KubeMemoryOverCommit',
    severity: 'Low',
    summary: 'kube-state-metrics is experiencing errors in list operations',
    startTime: new Date(),
    url: 'https://ncvocj.condense.zeliot.in/'
  },
  {
    sNo: '07',
    alertName: 'KubePodNotReady',
    severity: 'Null',
    summary: 'kube-state-metrics is experiencing errors in list operations',
    startTime: new Date(),
    url: 'https://ncvocj.condense.zeliot.in/'
  },
  {
    sNo: '08',
    alertName: 'KubeAPIDown',
    severity: 'Medium',
    summary: 'kube-state-metrics is experiencing errors in list operations',
    startTime: new Date(),
    url: 'https://ncvocj.condense.zeliot.in/'
  }
];
