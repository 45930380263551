import React from 'react';
import loadingAnimation from '../../assets/images/Loading Animation.gif';
import styled from 'styled-components';
import i18next from 'i18next';

const LoadingAnimation = styled.img`
  width: 100%;
  bottom: 4px;
`;
const ScrollAnimationBar = styled.div`
  width: 100%;
  height: 4px;
  background-color: #2f2f2f;
  border-radius: 2px;
  box-shadow: -8px 4px 9px 0px #2f2f2f;
  position: absolute;
  bottom: 0;
  left: 0;
`;
const ParentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(90vw + 200px);
  height: 100vh;
  background-color: #171717;
`;
const AnimationContainer = styled.div`
  position: relative;
  width: 240px;
`;
const Loader = () => {
  return (
    <ParentContainer>
      <AnimationContainer>
        <ScrollAnimationBar />
        <LoadingAnimation
          src={loadingAnimation}
          alt={i18next.t('PLACEHOLDERS.LOADING_ANIMATION_ALT')}
        />
      </AnimationContainer>
    </ParentContainer>
  );
};

export default Loader;
