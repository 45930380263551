const idConstants = {
  regex: {
    username1: new RegExp(/^[a-zA-Z0-9]{6,15}$/),
    username2: new RegExp(/^\d+$/),
    fullName: new RegExp(
      /^[a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńñòóôöõøśšùúûüųūýÿżźžÀÁÂÄÃÅĄČĆĘÈÉÊËĖĮÌÍÎÏŁŃÑÒÓÔÖÕØŚŠÙÚÛÜŲŪÝŸŻŹŽ.,'’-]+(?: [a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńñòóôöõøśšùúûüųūýÿżźžÀÁÂÄÃÅĄČĆĘÈÉÊËĖĮÌÍÎÏŁŃÑÒÓÔÖÕØŚŠÙÚÛÜŲŪÝŸŻŹŽ.,'’-]+)*$/
    ),
    emailId: new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/),
    forgotEmailId: new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/),
    password: new RegExp(
      /^(?=.*\d)(?=.*[A-Z])(?!.*[^a-zA-Z0-9!@#$%^&*_])(.{8,15})$/
    ),
    otp: new RegExp(/^[0-9]{6,6}$/)
  },
  path: {
    pageNotFound: '/page-not-found',
    login: '/login',
    createAccount: '/create-account',
    newUserOtpVerification: '/new-user-verification',
    support: '/support',
    forgotPassword: '/forgot-password',
    forgotPasswordOtpVerification: '/forgot-password-otp-verification',
    resetPassword: '/reset-password',
    dashboard: '/dashboard',
    deploymentDetails: '/dashboard/deployment-details/:id'
  },
  axiosMethods: {
    get: 'GET',
    post: 'POST'
  },
  otpInputFieldDigits: 6,
  pagination: { itemsPerPage: 10, maxVisiblePages: 2 },
  paginationWidthLabelData: [
    { column: 'clientId', width: 2, label: 'PAGINATION.CLIENT_ID' },
    { column: 'publicEndpoint', width: 2, label: 'PAGINATION.PUBLIC_ENDPOINT' },
    { column: 'bastionHostIp', width: 3, label: 'PAGINATION.BASTION_HOST_IP' },
    { column: 'location', width: 3, label: 'PAGINATION.LOCATION' },
    { column: 'plan', width: 1, label: 'PAGINATION.PLAN' },
    { column: 'deployedOn', width: 1, label: 'PAGINATION.DEPLPYED_ON' }
  ]
};

export default idConstants;
