import React, { useState, useContext } from 'react';
import InputField from '../../components/common/_loginInputField';
import { Col, Row } from 'reactstrap';
import { useHistory, withRouter } from 'react-router-dom';
import CommonLandingPage from './CommonLandingPage';
import ButtonLandingPage from '../LandingPages/CommonButtonLandingPage';
import { SnackbarContext } from '../../layouts/Context/snackBarContext';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import { axiosAdapter } from '../../utils';
import idConstants from '../../consts/idConstants';
import i18next from 'i18next';
import { CREATE_OTP } from '../../consts/configConstants';

const Text = styled.p`
  font-family: 'Articulat CF Medium';
  margin: 0;
`;

const CreateAccount = () => {
  const history = useHistory();
  const { showMessage } = useContext(SnackbarContext);

  const [username, setUsername] = useState('');
  const [fullName, setFullName] = useState('');
  const [emailId, setEmailId] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isUsernameValid, setIsUsernameValid] = useState(true);
  const [isFullNameValid, setIsFullNameValid] = useState(true);
  const [isEmailIdValid, setIsEmailIdValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [doesPasswordMatch, setDoesPasswordMatch] = useState(true);

  const handleChange = (event) => {
    const value = event.target.value;
    switch (event.target.name) {
      case 'username':
        setUsername(value.trim());
        break;
      case 'fullName':
        setFullName(value.trim());
        break;
      case 'email':
        setEmailId(value);
        break;
      case 'password':
        setPassword(value);
        break;
      case 'confirmPassword':
        setConfirmPassword(value);
        break;
    }
  };

  const checkUserName = () => {
    if (
      !idConstants.regex.username1.test(username) ||
      idConstants.regex.username2.test(username)
    ) {
      setIsUsernameValid(false);
      showMessage('VALIDATION.USERNAME');
      return false;
    } else {
      return true;
    }
  };

  const checkFullName = () => {
    if (
      !idConstants.regex.fullName.test(fullName) ||
      !Boolean(fullName.length >= 3 && fullName.length <= 250)
    ) {
      setIsFullNameValid(false);
      showMessage('VALIDATION.FULL_NAME');
      return false;
    } else {
      return true;
    }
  };

  const checkEmailId = () => {
    if (!idConstants.regex.emailId.test(emailId)) {
      setIsEmailIdValid(false);
      showMessage('VALIDATION.EMAIL_ID');
      return false;
    } else {
      return true;
    }
  };

  const checkPassword = () => {
    if (!idConstants.regex.password.test(password)) {
      setIsPasswordValid(false);
      showMessage('VALIDATION.PASSWORD');
      return false;
    } else {
      return true;
    }
  };

  const handleSubmitClick = async () => {
    let comparePassword;
    let checkPasswordResponse;

    setIsUsernameValid(true);
    setIsFullNameValid(true);
    setIsPasswordValid(true);

    const checkUserNameResponse = await checkUserName();
    const checkFullNameResponse = await checkFullName();
    const checkEmailIdResponse = await checkEmailId();

    if (
      checkUserNameResponse &&
      checkFullNameResponse &&
      checkEmailIdResponse
    ) {
      checkPasswordResponse = await checkPassword();
    }

    if (
      checkUserNameResponse &&
      checkFullNameResponse &&
      checkEmailIdResponse
    ) {
      //This comparePassword will return 0 (if passwords match), else -1 will be returned.
      comparePassword = password.localeCompare(confirmPassword);
      if (comparePassword === 0) {
        setDoesPasswordMatch(true);
      } else {
        setDoesPasswordMatch(false);
        showMessage(`PASSWORDS_DON'T_MATCH`);
      }
    }

    if (
      checkUserNameResponse &&
      checkFullNameResponse &&
      checkPasswordResponse &&
      comparePassword === 0
    ) {
      try {
        const createOtpResponse = await axiosAdapter(
          idConstants.axiosMethods.post,
          CREATE_OTP,
          {
            emailId: emailId,
            isNewAccount: true
          }
        );

        if (createOtpResponse.data.code === 200) {
          history.push({
            pathname: idConstants.path.newUserOtpVerification,
            username: username,
            password: password,
            emailId: emailId,
            fullName: fullName,
            isNewAccount: true
          });
        }
        showMessage('VALIDATION.OTP_FAILED');
      } catch (error) {
        showMessage('VALIDATION.OTP_FAILED');
      }
    }
  };

  return (
    <CommonLandingPage>
      <Row style={{ gap: '1rem' }}>
        <Col xs={12}>
          <Text
            style={{ color: '#F6F6F6', fontSize: '22px', lineHeight: '31px' }}
          >
            {i18next.t('WELCOME_MSG')}
          </Text>
        </Col>

        <Col xs={12}>
          <Row style={{ gap: '1rem' }}>
            <Col xs={12}>
              <InputField
                throwError={!isUsernameValid}
                name='username'
                placeholder={i18next.t('PLACEHOLDERS.USERNAME')}
                type='text'
                value={username}
                onChange={(e) => handleChange(e)}
              />
            </Col>

            <Col xs={12}>
              <InputField
                throwError={!isFullNameValid}
                name='fullName'
                placeholder={i18next.t('PLACEHOLDERS.FULL_NAME')}
                type='text'
                value={fullName}
                onChange={(e) => handleChange(e)}
              />
            </Col>

            <Col xs={12}>
              <InputField
                throwError={!isEmailIdValid}
                name='email'
                placeholder={i18next.t('PLACEHOLDERS.EMAIL_ID')}
                type='text'
                value={emailId}
                onChange={(e) => handleChange(e)}
              />
            </Col>

            <Col xs={12}>
              <InputField
                throwError={!isPasswordValid}
                name='password'
                placeholder={i18next.t('PLACEHOLDERS.PASSWORD')}
                type='password'
                value={password}
                onChange={(e) => handleChange(e)}
              />
            </Col>

            <Col xs={12}>
              <InputField
                throwError={!doesPasswordMatch}
                name='confirmPassword'
                placeholder={i18next.t('PLACEHOLDERS.CONFIRM_PASSWORD')}
                type='password'
                value={confirmPassword}
                onChange={(e) => handleChange(e)}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12}>
          <ButtonLandingPage
            disabled={
              username === '' ||
              fullName === '' ||
              password === '' ||
              confirmPassword === ''
            }
            onClickEvent={() => handleSubmitClick()}
            buttonPlaceholder={i18next.t('PLACEHOLDERS.CONTINUE')}
            linkLable={[
              <b
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '0.5rem',
                  color: '#777777'
                }}
              >
                {i18next.t('ALREADY_ACCOUNT')}
                <Link
                  to={idConstants.path.login}
                  className='d-flex align-items-center gap-2 text-white'
                >
                  <u>{i18next.t('LOGIN_NOW')}</u>
                </Link>
              </b>
            ]}
          />
        </Col>
      </Row>
    </CommonLandingPage>
  );
};

export default withRouter(CreateAccount);
