import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import CommonLandingPage from './CommonLandingPage';
import CustomRow from '../../components/common/_customRow';
import Column from '../../components/common/_customColumn';
import styled from 'styled-components';
import { Link, useHistory, useLocation, withRouter } from 'react-router-dom';
import ChevronRight from '../../assets/images/Chevron-left.svg';
import { axiosAdapter } from '../../utils';
import { SnackbarContext } from '../../layouts/Context/snackBarContext';
import i18next from 'i18next';
import idConstants from '../../consts/idConstants';
import { CREATE_OTP, VALIDATE_OTP } from '../../consts/configConstants';

const Text = styled.p`
  font-family: 'Articulat CF Medium';
  margin: 0;
`;

const InputField = styled.input`
  font-family: 'Articulat CF Medium';
  font-size: 18px;
  font-weight: 500;
  color: #f6f6f6;
  background-color: inherit;
  outline: none;
  border: none;
  width: 100%;
  text-align: center;
  &::placeholder {
    color: #777777;
  }
`;

const InputFieldContainer = styled.div`
  padding: 12px;
  width: 48px;
  height: 48px;
  border-radius: 12px;
  background-color: #2f2f2f;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #2f2f2f;
`;

const StyledButton = styled.button`
  border-radius: 12px;
  background-color: #47ccd6;
  border: 1px solid #47ccd6;
  padding: 13px;
  color: #ececec;
  font-family: 'Articulat CF Medium';
  font-size: 18px;
  font-weight: 500;
  width: 100%;
  min-height: 56px;
  &:disabled {
    opacity: 0.5;
  }
`;

const ForgotPasswordOtpVerification = (props) => {
  const location = useLocation();
  const history = useHistory();
  const { showMessage } = useContext(SnackbarContext);

  const [otp, setOtp] = useState(new Array(6).fill(''));
  const inputRefs = useRef([]);
  const [isOtpInvalid, setOtpInvalid] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isOtpValidationLoading, setOtpValidationLoading] = useState(false);

  // Layout effect to set the first input focus
  useLayoutEffect(() => {
    if (inputRefs.current[0]) inputRefs.current[0].focus();
  }, []);

  if (!location.state?.emailId) {
    history.push(idConstants.path.login);
  }

  const handleOtpChange = (element, index) => {
    if (isNaN(element)) return false;
    if (element === ' ') return false;

    setOtp([...otp.map((d, idx) => (idx === index ? element : d))]);

    // Focus next input
    if (element !== '') {
      if (index < 5) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleOtpKeydown = (event, index) => {
    if (event.key === 'Backspace' && index > 0 && otp[index] === '') {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleVerifyClick = async () => {
    setOtpInvalid(false);
    setOtpValidationLoading(true);

    try {
      const data = {
        otp: otp.join(''),
        emailId: location.state.emailId,
        isNewAccount: location.state.isNewAccount
      };
      await axiosAdapter(idConstants.axiosMethods.post, VALIDATE_OTP, data);
      setOtpValidationLoading(false);
      history.push(idConstants.path.resetPassword, data);
    } catch (err) {
      setOtpValidationLoading(false);
      if (err?.response?.data?.code === 400) {
        setErrorMessage(err?.response?.data.remarks);
        setOtpInvalid(true);
      } else {
        setErrorMessage(i18next.t('MESSAGES.ERROR.UNABLE_TO_VERIFY_OTP'));
      }
    }
  };

  const handleOtpResend = async () => {
    setOtpValidationLoading(true);
    try {
      await axiosAdapter(idConstants.axiosMethods.post, CREATE_OTP, {
        emailId: location.state.emailId,
        isNewAccount: false
      });

      setOtpValidationLoading(false);
      showMessage('MESSAGES.SUCCESS.OTP_SENT_SUCCESS', 'success');
    } catch (err) {
      setOtpValidationLoading(false);
      showMessage('MESSAGES.ERROR.OTP_SEND_FAILURE');
    }
  };

  return (
    <CommonLandingPage>
      <CustomRow>
        <Column style={{ marginBottom: '40px' }}>
          <Text
            style={{ color: '#F6F6F6', fontSize: '22px', lineHeight: '31px' }}
          >
            {i18next.t('HELPER_TEXTS.PLEASE_CONFIRM_EMAIL')}
          </Text>
        </Column>
        <Column style={{ marginBottom: errorMessage ? '10px' : '40px' }}>
          <CustomRow>
            {otp.map((num, index) => (
              <Column xs={1.8} key={index}>
                <InputFieldContainer
                  style={isOtpInvalid ? { borderColor: '#EA3A3A' } : {}}
                >
                  <InputField
                    placeholder='0'
                    maxLength={1}
                    value={num}
                    onChange={(e) => handleOtpChange(e.target.value, index)}
                    onKeyDown={(e) => handleOtpKeydown(e, index)}
                    ref={(el) => (inputRefs.current[index] = el)}
                  />
                </InputFieldContainer>
              </Column>
            ))}
          </CustomRow>
        </Column>
        {errorMessage && (
          <Column style={{ marginBottom: '12px' }}>
            <Text style={{ color: '#EA3A3A', fontSize: '12px' }}>
              {errorMessage}
            </Text>
          </Column>
        )}
        <Column style={{ marginBottom: '16px' }}>
          <StyledButton
            disabled={otp.some((d) => d === '') || isOtpValidationLoading}
            onClick={() => handleVerifyClick()}
          >
            {i18next.t('HELPER_TEXTS.VERIFY')}
          </StyledButton>
        </Column>
        <Column style={{ marginBottom: '50px' }}>
          <Text
            style={{
              color: '#777777',
              fontSize: '16px',
              lineHeight: '20.8px'
            }}
          >
            Didn’t get a code?{' '}
            <span
              style={{
                color: '#CCCCCC',
                cursor: 'pointer',
                textDecoration: 'underline'
              }}
              onClick={() => !isOtpValidationLoading && handleOtpResend()}
            >
              Click to Resend
            </span>
          </Text>
        </Column>
        <Column>
          <Link to={idConstants.path.login}>
            <Text style={{ color: '#CCCCCC', fontSize: '16px' }}>
              <img
                src={ChevronRight}
                alt={i18next.t('PLACEHOLDERS.CHEVRON_ALT')}
                style={{ height: '12px', width: '12px', marginRight: '10px' }}
              />
              Back to login
            </Text>
          </Link>
        </Column>
      </CustomRow>
    </CommonLandingPage>
  );
};

export default withRouter(ForgotPasswordOtpVerification);
